html,
body,
#root {
  height: 100%;
  width: 100%;
}

#localfilesystem-notification {
  max-width: 592px;
  margin: 0 auto;
  font-size: 12px;
  padding: 10px 10px;
}
