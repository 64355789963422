@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/_breakpoints';
@import '../../styles/bootstrap-variables';

.approved_estimate-page {
  &__call-containter {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7f9fc;
    padding: 32px;

    span {
      color: #332c4a;
      font-size: 19px;
      padding-bottom: 24px;
    }
  }

  &__call-button {
    background: #004dcb !important;
    color: #f2f2f2 !important;
    border-radius: 14px !important;
    font-size: 16px !important;
    padding: 13px !important;
    font-weight: bold !important;
    letter-spacing: 0.4px;

    a {
      color: #fff;
      font-size: 16px !important;

      &:focus {
        color: #fff;
      }

      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }
  }

  &__signature-container {
    margin-top: 2em;
    text-align: center;
    width: 100%;
  }

  &__signature-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;

    &__date {
      color: $gray-metal;
    }
  }

  &__signature-img {
    width: 100%;
  }

  &__signature-footnote {
    color: #908d9c;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    margin-bottom: 12px;
    margin-top: 16px;
  }
}
