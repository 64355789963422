.estimate-summary {
  &__fields {
    margin-top: 5px;
    line-height: 1.5;
    font-weight: 400;
    font-size: 12px;

    > * {
      margin-bottom: 0;
    }
  }

  &__notes {
    font-size: 16px;
    font-weight: 500;
    margin-top: 2em;
    margin-bottom: 2em;
  }
}
