@import '@jobox/react-components-library/mobile/styles/functions';
@import '@jobox/react-components-library/mobile/styles/variables';

@import './common.module';

.upcoming-list-item {
  font-size: 13px;
  display: flex;
  align-items: center;

  &__info {
    padding-right: 0.5rem;

    & > * {
      display: block;

      &:last-child {
        font-weight: 400;
      }
    }
  }

  &__action {
    margin-left: auto;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
  }
}

.list-item--holiday-personal {
  :global(.jobox__list-item__trigger__indicator) {
    width: auto;
    height: auto;
    flex: 1 0 auto;
    align-self: stretch;
  }

  :global(.jobox__pill) {
    display: block;
  }
}

.confirm-modal-content {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 24px;
  padding-bottom: 32px;

  &__title {
    font-size: 16px;
    font-weight: 600;
  }

  &__details {
    font-size: 24px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    margin-top: 8px;
  }
}

:global(.jobox__button).cta {
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-right: 24px;
  text-transform: none;
  text-align: left;
  padding: 16px;

  &::after {
    content: "";
    width: 48px;
    height: 28px;
    border-radius: 16px;
    display: inline-block;
    margin-left: auto;
  }

  &--select::after {
    background: url('./images/icon-arrow-right-blue.svg') center center no-repeat, theme-color($theme, 'blue', 300);
  }

  &--create::after {
    background: url('./images/icon-plus-blue.svg') center center no-repeat, theme-color($theme, 'blue', 300);
  }

  + .cta {
    margin-top: 8px;
  }
}

.upcoming {
  margin-top: 24px;
}
