@import '../../styles/bootstrap-variables';

.eb {
  &__summary-line {
    font-size: 16px;
    margin: 0.625em 0 0 0;
  }

  &__summary-line--prominent {
    font-weight: 600;

    span {
      font-weight: 400;
    }
  }

  &__summary-line--muted {
    color: $gray-metal;
  }

  &__summary-line--total {
    font-weight: 600;
  }

  &__summary-total-number {
    font-family: $font-family-lato;
    font-size: 20px;
  }

  &__divider {
    border-bottom: 1px solid $gray-400;
    height: 1px;
    margin: 1em 0;
  }
}
