@import '../../styles/bootstrap-variables';

.remote-approval-page {
  &__policy-notes-container {
    text-align: center;
    line-height: 1em;
    margin-bottom: 5px;
  }

  &__name-input {
    margin-top: 25px;
  }

  &__signature-container {
    margin: 25px 0;
    text-align: center;
    width: 100%;
  }

  &__signature-img {
    margin-top: 1.5em;
    width: 100%;
    background: red;
  }

  &__footer-notes {
    font-size: 12px;
    color: #908d9c;
  }

  &__signature-footnote {
    color: #908d9c;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }

  &__approve-button-container {
    background-color: #f7f9fc;
    padding: 15px 30px 30px 30px;
    max-width: 592px;
    margin: 0 auto;
  }

  &__approve-button {
    background: #004dcb !important;
    color: #f2f2f2 !important;
    border-radius: 14px !important;
    font-size: 16px !important;
    padding: 13px !important;
    font-weight: bold !important;
    letter-spacing: 0.4px;
  }

  &__approve-button-disabled {
    background: #dbdde2 !important;
    color: #f2f2f2 !important;
    border-radius: 14px !important;
    font-size: 16px !important;
    padding: 13px !important;
    font-weight: bold !important;
    letter-spacing: 0.4px;
    border: none !important;
  }

  &__loader-modal {
    width: 100px !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 9999 !important;

    &__loader {
      width: 90%;
      margin: auto;
    }
  }
}
