@import '../../../styles/bootstrap-variables';

.connect-tech-settings__status {
  background-color: $white-inactive;
  height: 100%;
  overflow: auto;

  &__body {
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 12px;

    &__info {
      font-family: $font-family-lato;
      font-size: 16px;
      margin-bottom: 24px;
    }
  }

  &__options-section {
    background-color: $white;
    margin-bottom: 16px;

    &__option {
      height: 64px;
      display: flex;
      justify-content: space-between;
      padding: 19px 23px;
    }

    &__title {
      color: $not-black;
      height: 24px;
      line-height: 19px;
      font-size: 15px;
    }

    &__arrow {
      height: 10px;
      width: 6px;
    }

    &__divider {
      height: 1px;
      background-color: #ececec;
    }
  }
}
