@import "../../styles/bootstrap-variables";

.input__container {
  margin-top: 3.4em;
}

.input__input-group {
  border: none !important;
  background-color: #f6f9fe !important;
  border-radius: 10px !important;
}

.input__input-group-addon {
  width: 1.5em;
  margin-right: 2px;
  margin-left: 21px;

  > img {
    max-width: 100%;
  }

  span.input-group-text {
    border: none !important;
  }
}

.input__input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
}

.input__floating_input_label {
  width: 100%;
  margin: 17px 14px;
}

.input__floating_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 1.5em;
  font-size: inherit;
}

.input__floating_label {
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  color: #646466;
  bottom: 0;
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  font-size: 1em;
  cursor: text;
  pointer-events: none;
  width: 66.6%;
  transform: none;
}

.input__floating_label__active {
  transform: translate3d(0, -40%, 0) scale(0.75);
}

.input__floating_input {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  font-size: 1em;
  background-color: #f000;
  transition: all 0.2s ease-in-out;
  transform-origin: left bottom;
  transform: none;

  &:focus {
    background-color: #f000;
  }

  &::placeholder {
    color: #646466;
    opacity: 0;
  }
}

.input__floating_input__active {
  transform: translate3d(0, 30%, 0);

  &::placeholder {
    opacity: 1;
  }
}
