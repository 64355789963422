@import '../../styles/bootstrap-variables';

.connect-tech-settings__day {
  &__header {
    display: flex;
    justify-content: space-between;
    margin: 24px;
    height: 30px;
    font-size: 24px;
    line-height: 30px;
    vertical-align: center;

    &__title {
      font-family: $font-family-monserrat;
      font-weight: $font-weight-bold;
    }

    &__button {
      background-color: transparent;
      border: none;
      height: 30px;
      width: 30px;
      font-size: 25px;
      color: $not-black;
    }
  }

  &__question_header {
    height: 168px;
    background: #f6f9fe;
    margin: 0;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__divider {
    height: 1px;
    margin-left: 24px;
    margin-right: 24px;
    background-color: #ececec;
  }

  &__all-day {
    display: flex;
    justify-content: space-between;
    margin: 28px;
    font-family: $font-family-monserrat;
    font-weight: $font-weight-bold;
    font-size: 16px;
    line-height: 20px;
    align-items: center;

    &__toggle {
      background-color: transparent;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }

  &__block {
    margin-left: 24px;
    margin-bottom: 24px;

    &__label {
      margin-top: 8px;
      color: $gray-font;
      font-size: 13px;
    }
  }

  &__range {
    display: flex;

    &__unit {
      background: $white-inactive;
      height: 60px;
      width: 130px;
      padding: 12px 16px;
      margin-right: 2px;
      font-family: $font-family-lato;
      color: $gray-medium;
      font-size: 13px;

      &.right {
        border-radius: 8px 0 0 8px;
      }

      &.left {
        border-radius: 0 8px 8px 0;
      }

      &.readonly {
        width: 162px;
        background: $white-static;
      }
    }

    &__input {
      background: $white-inactive;
      border: none;
      width: 100px;
      height: 20px;
      color: $not-black;
      font-size: 16px;
      line-height: 16px;
      padding: 0;

      &::-webkit-calendar-picker-indicator {
        background: none;
        display: none;
      }
    }

    &__delete {
      appearance: none;
      padding: 0;
      border: none;
      background: $white-inactive;
      height: 60px;
      width: 64px;
      border-radius: 0 8px 8px 0;

      &__icon {
        padding: 22px 24px;
      }
    }

    &__display {
      color: $gray-metal;
      font-size: 16px;
      line-height: 19px;
    }
  }
}
