@import '../../styles/bootstrap-variables';

.header {
  background-image: url('../../images/header-geometric-small.svg');
  background-color: $blue;
  color: white;
  object-fit: contain;
  overflow: hidden;
  padding: 13px 17px 17px 17px;
}

.header__row {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 12px;
}

.header__contacts-separator {
  display: none;
  margin-left: 12px;
  margin-right: 12px;
}

.header__brand-name {
  font-family: $font-family-monserrat;
  font-weight: $font-weight-bold;
  text-transform: uppercase;

  &:hover {
    text-decoration: none !important;
  }
}

.header__contacts {
  display: flex;
  font-size: 14px;
  text-align: right;
  flex-direction: column;
  margin-left: auto;
}

.header__title,
.header__text {
  margin-right: 29px;
  margin-left: 24px;
}

.header__title {
  font-size: 24px;
}

.header__text {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 23px;
}

@media all and (min-width: 521px) {
  .header {
    background-image: url('../../images/header-geometric-large.svg');
  }
}

@media all and (min-width: 768px) {
  .header {
    padding: 25px 38px;
  }

  .header__row {
    font-size: 20px;
  }

  .header__brand-name {
    font-size: 20px;
  }

  .header__title {
    font-size: 48px;
    margin-top: 56px;
  }

  .header__text {
    font-size: 28px;
  }

  .header__contacts {
    font-size: 20px;
    flex-direction: row;
  }

  .header__contacts-separator {
    display: block;
  }
}
