.nav-tabs {
  border-bottom: none;
}

.nav-pills {
  .nav-link {
    background-color: $nav-pills-link-active-bg !important;
    color: $nav-pills-link-active-color !important;
    cursor: pointer;
    font-family: $font-family-monserrat;
    font-size: 18px;
    text-align: center;
  }

  .nav-link.active {
    color: $nav-pills-link-active-color;
    background-color: $nav-pills-link-active-bg;
  }
}
