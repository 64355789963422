@import '../../styles/bootstrap-variables';

.help-page__title {
  font-size: 22px;
  margin-bottom: 18px;
}

.help-page__help-form {
  margin-bottom: 32px;
}

.help-page__confirmation {
  font-size: 12px;
  color: #979797; // $text-muted
}

.help-page__return-to-main {
  text-align: center;
}

.help-page__error {
  color: #bf6969;
  font-family: $font-family-lato;
  font-size: 12px;
  margin-bottom: 12px;
}

@media all and (min-width: 768px) {
  .help-page__title {
    font-size: 36px;
  }

  .help-page__help-form,
  .help-page__confirmation {
    max-width: 700px;
    width: 100%;
  }

  .help-page__footer {
    display: flex;
  }

  .help-page__return-to-main {
    margin-left: auto;
    white-space: nowrap;
  }
}
