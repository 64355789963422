:global(.jobox__backdrop) {
  &.backdrop {
    padding: 0;
    justify-content: flex-end;
  }

  .modal {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 8px 8px 32px 8px;
  }
}

.modal-content {
  &__actions {
    display: flex;
    flex-direction: column;

    > * + * {
      margin-top: 8px;
    }
  }
}
