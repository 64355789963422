@import '../../styles/bootstrap-variables';

.unsubscribe-page__content {
  margin: 0 auto;
  max-width: 46rem;
}

.unsubscribe-page__title {
  font-size: $font-size-lg;
  font-weight: normal;
}
