@import '../../../styles/bootstrap-variables';

$thank-you-color: #b96f0b;

.feedback__service {
  font-family: $font-family-monserrat;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  margin-bottom: 1rem;
}

.feedback__stars_container {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.feedback__stars {
  display: flex;
  width: 250px;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 1rem;
}

.feedback__select {
  font-family: $font-family-lato;
  font-size: 16px;
  color: rgba(100, 100, 102, 0.6);
}

.feedback_star {
  cursor: pointer;

  &--disabled {
    cursor: not-allowed;
  }
}
