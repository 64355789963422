@import '@jobox/react-components-library/mobile/styles/functions';
@import '@jobox/react-components-library/mobile/styles/variables';

.layout {
  background-color: theme-color($theme, 'white', 900) !important;

  &__content {
    padding: 24px 24px 48px 24px !important;
    display: flex;
    flex-direction: column;
  }
}

.head {
  padding-bottom: 0;

  h1 {
    border-bottom: 1px solid theme-color($theme, 'gray', 700);
    padding-bottom: 24px;
    margin-bottom: 0;
  }
}

.validation {
  padding: 8px 16px;
  color: theme-color($theme, 'red', 500);

  &__entry {
    display: none;

    &:first-child {
      display: block;
    }
  }
}

.dates {
  display: flex;

  > * {
    flex: 1 1 auto;

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-left: 1px solid theme-color($theme, 'white', 900);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.submit {
  margin-top: auto;
}
