.tabs {
  .nav {
    flex-direction: column;
    margin-bottom: 32px !important;
  }

  &.tabs--active {
    .nav-link {
      margin-top: 0 !important;
    }

    a.nav-link:not(.active) {
      display: none;
    }
  }

  .nav-link + .nav-link {
    margin-top: 23px;
  }
}

@media all and (min-width: 768px) {
  .tabs {
    .nav {
      flex-direction: row;
    }
  }

  .nav-link {
    width: 160px;
  }

  .nav-link + .nav-link {
    margin: 0 0 0 24px !important;
  }

  .tabs--active {
    a.nav-link:not(.active) {
      background-color: #ebca94 !important;
      display: block;
    }

    .nav-link {
      margin-top: 0 !important;
    }
  }
}
