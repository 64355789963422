@mixin select-container {
  width: 100%;
  height: 56px;
  background: #f6f9fe;
  border-radius: 10px;
  border: none;
  padding-left: 16px;
  padding-right: 31px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
