@import "./../../styles/bootstrap-variables";

.war-info {
  padding: 16px;
  background-color: $white-static;
  border-radius: 8px;

  &__header {
    color: $not-black;
    font-family: $font-family-lato;
    font-size: 14px;
  }

  &__content {
    color: $gray-manatee;
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 0;
  }
}
