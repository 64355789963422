@import '../../../styles/bootstrap-variables';

.payment-details__type {
  flex: 1;
}

.payment-details__amount {
  align-self: flex-end;
}

.payment-details__row {
  align-items: center;
  display: flex;
  flex-direction: row;

  &.payment-details__row--refund {
    .payment-details__amount {
      color: $red;
    }
  }

  &--prominent {
    .payment-details__type {
      font-weight: 600;
    }
  }

  &--muted {
    color: $gray-metal;
  }

  &--total {
    font-weight: 600;
  }
}

.payment-details__row + .payment-details__row {
  margin-top: 0.75rem;
}
