@import '../../../styles/bootstrap-variables';

.modal {
  display: flex;
  justify-content: center;
  align-items: center;

  &__background {
    width: 100%;
    height: 100%;
    background: $not-black;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__foreground {
    background-color: $white;
    border-radius: 16px 16px 0 0;
    position: absolute;
    bottom: 0;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
  }
}
