@import '../../../styles/bootstrap-variables';

.deactivate-modal {
  display: flex;
  justify-content: center;
  align-items: center;

  &__background {
    width: 100%;
    height: 100%;
    background: $not-black;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__foreground {
    background-color: $white;
    border-radius: 16px 16px 0 0;
    position: absolute;
    bottom: 0;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
  }

  &__title {
    color: $black;
    font-size: 16px;
    font-weight: 700;
    margin-top: 26px;
  }

  &__description {
    color: $black;
    font-size: 24px;
    margin-top: 12px;
    font-weight: 700;
  }

  &__primary-button {
    width: 342px;
    height: 48px;
    background-color: $red;
    color: $white-inactive;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    border-radius: 16px;
    border: none;
    font-size: 14px;
    font-weight: 700;

    &__loading {
      width: 30px;
      height: 30px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  &__secondary-button {
    width: 342px;
    height: 48px;
    background-color: #e6f0ff;
    color: $blue-dark;
    margin: 8px auto 44px auto;
    border-radius: 16px;
    border: none;
    font-size: 14px;
    font-weight: 700;
  }
}
