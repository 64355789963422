@import '../../../styles/bootstrap-variables';

.payment-log-details__payments {
  margin-bottom: .75rem;
  padding-bottom: 1.5rem;
}

.payment-log-details__type {
  flex: 1;
}

.payment-log-details__info {
  display: flex;
  align-items: center;
}

.payment-log-details__amount {
  align-self: flex-end;
  font-weight: $font-weight-bold;
  text-align: right;
  margin-left: auto;

  &__tip-breakdown {
    display: block;
    color: $text-muted;
    font-size: 12px;
    font-weight: 400;
  }
}

.payment-log-details__payment + .payment-log-details__payment {
  margin-top: 1rem;
}

.payment-log-details__payment--total {
  margin-bottom: 2.5rem;

  .payment-log-details__type {
    font-family: $font-family-monserrat;
    font-weight: $font-weight-bold;
  }

  .payment-log-details__amount {
    font-size: 20px;
    font-weight: $font-weight-bolder;
  }
}

.payment-log-details__payment--refund,
.payment-log-details__payment--ccrefund {
  .payment-log-details__type {
    margin-left: 2.25rem;
  }

  .payment-log-details__amount {
    color: $red;
    font-weight: $font-weight-normal;
  }
}

.payment-log-details__payment--refund + .payment-log-details__payment--refund {
  margin-top: 0;
}

.payment-log-details__payment + .payment-log-details__payment--ccrefund,
.payment-log-details__payment--ccrefund + .payment-log-details__payment--ccrefund {
  margin-top: 0;
}

.payment-log-details__row {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
}

.payment-log-details__icon--empty {
  display: inline-block;
}

.payment-log-details__icon--empty,
.payment-log-details__icon {
  margin-right: .75rem;
  width: 1.5rem;
}

.payment-log-details__details {
  color: $text-muted;
  flex: 1;
  font-size: $font-size-sm;
  text-align: right;
}

.payment-log-details__total-label {
  font-family: $font-family-monserrat;
  flex: 1;
}

.payment-log-details__total-amount {
  font-size: 1.25rem;
}

.payment-log-details__signature + .payment-log-details__signature {
  margin-top: 1rem;
}
