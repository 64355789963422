@import '../../styles/bootstrap-variables';

.transaction-page__title {
  font-size: 22px;
  margin-bottom: 18px;
}

.transaction-page__job-card {
  margin-bottom: 72px;
}

.transaction-page__subtitle {
  font-size: 18px;
  margin-bottom: 24px;
}

.transaction-page__question-form {
  margin-bottom: 10px;
}

.transaction-page__tab-error {
  color: #bf6969;
  font-family: $font-family-lato;
  font-size: 12px;
}

.transaction-page__tabs {
  margin-bottom: 50px;

  p {
    font-size: 18px;
  }

  .transaction-page__note {
    font-size: initial;
  }
}

.transaction-page__acknowledgement {
  color: #979797; // $text-muted
  font-size: 12px;
  margin-right: 16px;
}

.transaction-page__return-to-main {
  text-align: center;
}

@media all and (min-width: 768px) {
  .transaction-page__title {
    font-size: 36px;
    margin-bottom: 48px;
  }

  .transaction-page__subtitle {
    font-size: 24px;
    margin-bottom: 48px;
  }

  .transaction-page__content {
    margin: 0 auto;
  }

  .transaction-page__job-card,
  .transaction-page__tabs,
  .transaction-page__acknowledgement {
    max-width: 800px;
    width: 100%;
  }

  .transaction-page__footer {
    display: flex;
  }

  .transaction-page__return-to-main {
    margin-left: auto;
    white-space: nowrap;
  }
}
