
.question-form {
  display: flex;
  flex-direction: column;
}

.question-form__submit-button {
  width: 224px;
  align-self: center;
}

@media all and (min-width: 992px) {
  .question-form {
    .form-control[type=text],
    .form-control[type=number] {
      max-width: 480px;
    }
  }

  .question-form__phone {
    max-width: 180px !important;
  }

  .question-form__submit-button {
    align-self: flex-end;
  }
}
