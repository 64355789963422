@import '../../styles/bootstrap-variables';

.receipt-page {
  display: flex;
  flex-direction: column;
}

.receipt-page__section--centered {
  text-align: center;
}

.receipt-page__service-provider-phone,
.receipt-page__service-provider-license {
  font-size: $font-size-sm;
}

.receipt-page__section-header {
  font-family: $font-family-monserrat;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  margin-bottom: 0.5rem;
  word-break: break-word;
}

.receipt-page__divider {
  background-color: $gray-400;
  height: 1px;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.receipt-page__tech_affiliation {
  font-size: 16px;
  height: 14px;
  color: #646466;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.receipt-page__partner-brand-logo {
  height: 64px;
  width: 215px;
  margin-left: calc(50% - 107.5px);
}

.receipt-page__job-ref-id {
  border-radius: 100px;
  color: $white;
  font-size: $font-size-sm;
  height: 1.5rem;
  line-height: 1.5rem;
  margin: 0 auto;
  position: relative;
  text-align: center;
  top: 1.25rem;
  width: 9rem;
}

.receipt-page__job-ref-id + .receipt-page__divider {
  margin-bottom: 2rem;
  margin-top: .5rem;
}

.receipt-page__total-payment {
  justify-content: center;
  margin: 0 auto;
}

.receipt-page__location {
  margin-bottom: 1.5rem;
}

.receipt-page__payment-details {
  margin-bottom: 2.5rem;
  margin-top: 1rem;
}

.receipt-page__feedback {
  text-align: center;
}

.receipt-page__payment-row {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.receipt-page__payment-row + .receipt-page__payment-row {
  margin-top: 0.75rem;
}

.receipt-page__payment-type {
  flex: 1;
}

.receipt-page__payment-amount {
  align-self: flex-end;
}

.receipt-page__billing-phone-link {
  margin-top: 0.75rem;
}

.receipt-page__map-wrapper {
  text-align: center;
}

.receipt-page__map {
  margin-top: .75rem;
  max-width: 330px;
  width: 100%;
}

.receipt-page__warranty {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.2;
  color: $gray-manatee;
}
