@import '../../../styles/bootstrap-variables';

.payment-signature {
  background-color: $white;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
  border-radius: .625rem;
  border: .125rem dashed #ccd3dc;
  text-align: center;
}

.payment-signature__title {
  font-family: $font-family-monserrat;
  font-weight: $font-weight-bold;
  margin-top: .5rem;
}

.payment-signature__image {
  margin-bottom: .0625rem;
  max-width: 100%;
}

.payment-signature__approval-text {
  color: $text-muted;
  font-size: $font-size-sm;
  letter-spacing: 0.3px;
  padding: 0 1rem 1rem 1rem;
}

.payment-signature--error {
  background-image: repeating-linear-gradient(45deg, transparent 0, transparent 3px, rgba(204, 211, 220, 0.3) 3px, rgba(204, 211, 220, 0.3) 5px);
  color: #6c728b;
  height: 195px;
}

.payment-signature__error-title {
  font-family: $font-family-monserrat;
  font-weight: $font-weight-bold;
  margin-top: 4rem;
}
