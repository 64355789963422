$tablet-width: 768px;

.react-pdf__Page__canvas {
  width: 80% !important;
  height: unset !important;
}

@media (max-width: #{$tablet-width}) {
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: unset !important;
  }
}

.react-pdf__Page__svg {
  width: 80% !important;
  height: unset !important;

  svg {
    width: 100% !important;
    height: unset !important;
  }
}

@media (max-width: #{$tablet-width}) {
  .react-pdf__Page__svg {
    width: 100% !important;
    height: unset !important;

    svg {
      width: 100% !important;
      height: unset !important;
    }
  }
}
