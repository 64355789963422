@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/_breakpoints';
@import '../../styles/bootstrap-variables';

.estimate-page {
  &__approved-container {
    background-color: #e6f1ff;
    font-size: 12px;
    font-weight: 700;
    padding: 1em 30px;
    text-align: right;
    font-family: Lato, sans-serif;
  }

  &__approved-color {
    color: #00ad91;
  }

  &__approved-date {
    display: inline-block;
    font-weight: 500;
  }

  &__signature-container {
    margin-top: 1.5em;
    text-align: center;
    width: 100%;
  }

  &__signature-text {
    font-family: $font-family-lato;
  }

  &__signature-img {
    margin-top: 1.5em;
    width: 100%;
  }

  &__signature-footnote {
    color: #908d9c;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }
}
