@import '../../../styles/bootstrap-variables';

.connect-tech-settings__main-nav-section {
  display: flex;
  flex-direction: row;
  background-color: $white;
  height: 64px;
  border-radius: 16px;

  &__icon {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 12px;
    margin-right: -4px;
  }

  &__text-area {
    flex: 1;
    margin-top: 11px;
    margin-left: 16px;
  }

  &__title {
    font-family: $font-family-monserrat;
    font-weight: $font-weight-bold;
    color: $not-black;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 2px;

    &.centered {
      line-height: 42px;
    }
  }

  &__subtitle {
    font-family: $font-family-lato;
    font-weight: $font-weight-normal;
    font-size: 16px;
    line-height: 19px;
    color: $gray-metal;
  }

  &__arrow {
    line-height: 10px;
    margin: 27px 24px;
  }
}

.connect-tech-settings__main-availability {
  &__day-container {
    display: flex;
  }

  &__day {
    margin-right: 4px;
    color: $gray-stone;

    &.active {
      color: $body-color;
    }
  }
}

.connect-tech-settings__skillset-section {
  margin-bottom: 16px;
}
