@import './common.module';

.list-group {
  &:global(.jobox__list-group) {
    padding-left: 0;
    padding-right: 0;
  }

  :global(.jobox__list-group__header) {
    padding-left: 24px;
    padding-right: 24px;
  }

  :global(.jobox__list-group__title) {
    width: 100%;
  }

  :global(.jobox__list-group__inner) {
    margin-right: 0;
  }

  :global(.jobox__list-group__inner .jobox__list-item) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.list-group-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
