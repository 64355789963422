.help-form {
  display: flex;
  flex-direction: column;
}

.help-form__receipt-copy-group {
  background-color: #e6f1ff;
  padding: 12px 36px !important;
}

.help-form__submit-button {
  align-self: center;
  width: 224px;
}

@media all and (min-width: 768px) {
  .help-form {
    .form-control[type=text],
    .form-control[type=number] {
      max-width: 480px;
    }
  }

  .help-form__phone,
  .help-form__amount,
  .help-form__date,
  .help-form__last4 {
    max-width: 180px !important;
  }

  .help-form__submit-button {
    align-self: flex-end;
  }
}
