@import '../../styles/bootstrap-variables';

.estimate-tech-header {
  text-align: center;
  margin-bottom: 31px;

  .partner-brand {
    width: 100%;

    &__affiliate {
      display: block;
      font-family: $font-family-lato;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 8px;
      color: #646466;
    }

    &__logo {
      height: 64px;
      width: 215px;
      margin-left: calc(50% - 107.5px);
    }
  }

  .tech-info {
    &__title {
      font-family: $font-family-monserrat;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;
    }

    &__sub {
      display: block;
      font-family: $font-family-lato;
      font-size: 10px;
      font-weight: 400;
      margin-top: 4px;
    }
  }
}
