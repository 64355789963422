@import '@jobox/react-components-library/mobile/styles/functions';
@import '@jobox/react-components-library/mobile/styles/variables';

.layout {
  background-color: theme-color($theme, 'white', 900) !important;

  :global(.jobox__view-layout__content) {
    background-color: #f7faff;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 25px 25px 16px 25px;
  }
}

.head {
  margin-bottom: 24px;
}

.list {
  :global(.jobox__list-item) {
    padding: 13px 16px;
  }

  :global(.jobox__list-item + .jobox__list-item) {
    margin-top: 8px;
  }
}

.list-item {
  @keyframes highlight {
    from { background-color: theme-color($theme, 'white', 900); }
    to { background-color: theme-color($theme, 'blue', 200); }
  }

  &--highlighted {
    animation: highlight 500ms ease-in-out 500ms 2 alternate;
  }
}

.list-item-content {
  display: flex;
  align-items: center;

  &__info {
    display: flex;
    flex-direction: column;

    > *:first-child {
      font-weight: 600;
      font-size: 16px;
    }

    > *:last-child {
      font-weight: 400;
      font-size: 13px;
    }
  }

  &__switch {
    flex: 0 0 auto;
    margin-left: auto;
  }
}
