@import '../../styles/bootstrap-variables';

.connect-tech-settings__availability {
  background-color: $white-inactive;
  height: 100%;
  overflow: auto;

  &__title {
    margin-bottom: 32px;
  }

  &__day-section {
    background-color: $white;
    margin-bottom: 16px;
    border-radius: 16px;

    &__header {
      height: 56px;
      display: flex;
      justify-content: space-between;

      &__title {
        font-family: $font-family-monserrat;
        font-weight: $font-weight-bold;
        font-size: 18px;
        line-height: 56px;
        margin-left: 24px;
      }

      &__toggle {
        margin: 16px;
      }
    }

    &__divider {
      height: 1px;
      margin-left: 24px;
      background-color: #ececec;
    }

    &__time-section {
      height: 56px;
      display: flex;
      justify-content: space-between;
      margin-left: 24px;

      &__windows {
        display: flex;
      }

      &__window {
        background-color: $white-inactive;
        color: $not-black;
        height: 24px;
        margin: 16px 8px 16px 0;
        padding: 4px 8px 4px 8px;
        line-height: 16px;
        border-radius: 4px;
        font-size: 13px;
      }

      &__arrow {
        height: 10px;
        width: 6px;
        margin: 23px 23px 23px 11px;
      }
    }
  }

  &__loading {
    height: 18px;
  }
}
