@import '@jobox/react-components-library/mobile/styles/functions';
@import '@jobox/react-components-library/mobile/styles/variables';

.date {
  position: relative;
  background-color: #f6f9fe;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  margin-bottom: 0;

  &__input {
    position: absolute;
    inset: 0;
    opacity: 0;
  }

  &__display {
    font-weight: 600;
    font-size: 16px;
  }

  &__label {
    font-size: 13px;
    color: theme-color($theme, 'dark', 700);
  }
}
