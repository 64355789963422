@import '../../../styles/bootstrap-variables';

.connect-tech-settings__deactivate {
  background-color: $white-inactive;
  height: 100%;
  overflow: auto;

  &__body {
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 12px;
    font-family: $font-family-lato;
    font-size: 16px;
  }

  &__bottom-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    &__holiday-alert {
      width: 327px;
      height: 63px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      background-color: #ffe9a7;
      border-radius: 15px;
      margin-left: 32px;
      margin-right: 32px;
      margin-bottom: 32px;

      &__alert-icon {
        margin-left: 14px;

        &__img {
          max-width: 22px;
          max-height: 22px;
        }
      }

      &__text {
        margin-left: 15px;

        &__primary {
          margin-top: 12px;
          margin-bottom: 0;
          font-family: $font-family-lato;
          font-weight: 700;
          font-size: 16px;
          color: $not-black;
        }

        &__secondary {
          margin-bottom: 12px;
          margin-top: 0;
          font-family: $font-family-lato;
          font-weight: 600;
          font-size: 14px;
          color: #626275;
        }
      }

      &__arrow-icon {
        margin-left: auto;
        margin-right: 7px;

        &__img {
          max-width: 24px;
          max-height: 24px;
        }
      }
    }

    &__button {
      width: 342px;
      height: 48px;
      background-color: $red;
      color: $white;
      margin-left: 24px;
      margin-right: 24px;
      margin-bottom: 48px;
      border-radius: 16px;
      border: none;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
