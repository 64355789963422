@import '../../styles/bootstrap-variables';

.estimate-reference {
  text-align: center;
  position: relative;
  margin: 1.5em auto;
  margin-top: 0;
  width: 100%;
  border-bottom: 1px solid $gray-400;

  &__job-id {
    display: inline-block;
    position: absolute;
    bottom: -15px;
    left: calc(50% - 80px);
    width: 160px;
    height: 30px;
    padding: .5em;
    background-color: #f5a623;
    font-size: 12px;
    color: white;
    font-family: $font-family-lato;
    font-weight: 400;
    text-align: center;
    border-radius: 20px;
  }

  &__job-id-reference {
    font-weight: 600;
  }
}
