@import '../../../styles/bootstrap-variables';

.connect-tech-settings__activate {
  background-color: $white-inactive;
  height: 100%;
  overflow: auto;

  &__body {
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 12px;
    font-family: $font-family-lato;
    font-size: 16px;
  }

  &__bottom-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    &__button {
      width: 342px;
      height: 48px;
      background-color: $blue-dark;
      color: $white;
      margin-left: 24px;
      margin-right: 24px;
      margin-bottom: 48px;
      border-radius: 16px;
      border: none;
      font-size: 14px;
      font-weight: 700;

      &__loading {
        width: 30px;
        height: 30px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}
