@import '../../styles/bootstrap-variables';

.request-sent-page {
  text-align: center;
}

.request-sent-page__title {
  font-size: 22px;
  margin-bottom: 18px;
}

.request-sent-page__text {
  font-size: 18px;
  margin-bottom: 24px;
}

.request-sent-page__image {
  margin-bottom: 24px;
}

@media all and (min-width: 768px) {
  .request-sent-page__title {
    font-size: 48px;
    margin-bottom: 32px;
  }

  .request-sent-page__text {
    font-size: 24px;
    margin-bottom: 48px;
  }

  .request-sent-page__image {
    height: 152px;
    margin-bottom: 42px;
    width: 152px;
  }
}
