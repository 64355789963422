@import '../../styles/bootstrap-variables';

.connect-tech-settings__car-brands {
  background-color: $white-inactive;
  min-height: 100%;

  &__fixed {
    position: fixed;
    background-color: $white-inactive;
    width: 100%;
  }

  &__brand-toggles {
    padding-top: 220px;
    padding-bottom: 20px;
  }

  &__header {
    margin-top: 36px;
  }

  &__nav-title {
    position: absolute;
    text-align: center;
    width: 112px;
    height: 50px;
    top: 36px;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-family: $font-family-monserrat;
    color: $gray-metal;
    font-size: 16px;
  }

  &__page-title {
    position: absolute;
    width: 210px;
    height: 28px;
    left: 82px;
    top: 36px;
    text-align: center;
    color: $gray-metal;
    font-family: $font-family-monserrat;
    font-weight: 600;
  }

  &__title {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;

    &__actions {
      display: flex;
      justify-content: space-between;
      width: 120px;
      margin-top: 36px;
      margin-right: 16px;
      font-family: $font-family-lato;
      color: $gray-metal;
      font-weight: 400;
      font-size: 16px;
    }
  }

  &__search {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 32px;
    padding-left: 32px;
    border: none;
    border-radius: 10px;
    height: 32px;
    width: calc(100% - 32px);
  }

  &__search-icon {
    position: absolute;
    left: 24px;
    top: 152px;
  }
}
