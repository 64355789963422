.estimates-common {
  &__call-containter {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7f9fc;
    padding: 32px;

    span {
      color: #332c4a;
      font-size: 19px;
      padding-bottom: 24px;
    }
  }

  &__call-button {
    background: #004dcb !important;
    color: #f2f2f2 !important;
    border-radius: 14px !important;
    font-size: 16px !important;
    padding: 13px !important;
    font-weight: bold !important;
    letter-spacing: 0.4px;

    a {
      color: #fff;
      font-size: 16px !important;

      &:focus {
        color: #fff;
      }

      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }
  }

  &__warranty {
    margin-top: 1.75rem;
  }

  &__notes {
    font-size: 16px;
    font-weight: 500;
    margin-top: 1em;
    color: #908d9c;
    padding-top: 20px;
  }
}
