@import '../../../styles/bootstrap-variables';

.availability-modal {
  &__title {
    color: $black;
    font-size: 24px;
    font-weight: 700;
    margin-top: 26px;
  }

  &__description {
    color: $black;
    font-size: 14px;
    margin-top: 9px;
    font-weight: 500;
  }

  &__primary-button-enable {
    width: 342px;
    height: 48px;
    background-color: $blue-dark;
    color: $white-inactive;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    border-radius: 16px;
    border: none;
    font-size: 14px;
    font-weight: 700;

    &__loading {
      width: 30px;
      height: 30px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  &__primary-button-disable {
    width: 342px;
    height: 48px;
    background-color: $red;
    color: $white-inactive;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    border-radius: 16px;
    border: none;
    font-size: 14px;
    font-weight: 700;

    &__loading {
      width: 30px;
      height: 30px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  &__secondary-button {
    width: 342px;
    height: 48px;
    background-color: #e6f0ff;
    color: $blue-dark;
    margin: 8px auto 44px auto;
    border-radius: 16px;
    border: none;
    font-size: 14px;
    font-weight: 700;
  }
}
