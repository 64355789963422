@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins/_breakpoints';
@import '../../styles/bootstrap-variables';

.estimate-layout-wrap {
  background-color: #f7f9fc;
  color: $body-color;

  @include media-breakpoint-up(sm) {
    min-height: 100vh;
    overflow: auto;
  }
}

.estimate-layout {
  margin: 0 auto;
  font-family: $font-family-lato;
  font-weight: 400;
  max-width: 592px;
  padding: 20px 30px;
  background-color: white;
  border-radius: 20px;

  @include media-breakpoint-up(sm) {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  &__body {
    margin-top: 1em;
  }

  &--invoice {
    .estimate-layout__body {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
