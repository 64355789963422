.container {
  width: calc(100% - 32px);
  border-radius: 16px 16px 0 0;
  position: fixed;
  bottom: 0;
  overflow: hidden;
  background: white;
  transition: transform 0.5s;
  margin: 16px 16px 0 16px;
}

.container-onboarding {
  bottom: 100px;
}

.container-enabled {
  transform: translateY(100%);
}

.title-container {
  height: 84px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  text-align: left;
  width: 90%;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.item {
  height: 56px;
  width: 100%;
  background: #f6f9fe;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px 0;
}

.item-text {
  text-align: left;
  width: 90%;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #0578ff;
}

.footer {
  height: 50px;
}
