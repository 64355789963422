@import "../../styles/bootstrap-variables";

.signature__container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.signature__sigCanvas {
  background-color: #f6f9fe;
}

.signature__line {
  border-bottom: 2px solid #0578ff;
  width: calc(100% - 40px);
  z-index: 998;
  position: absolute;
  bottom: 75px;
  pointer-events: none;
}

.signature__line-empty {
  border-bottom: 2px solid #332c4a;
  width: calc(100% - 40px);
  z-index: 9998;
  position: absolute;
  bottom: 75px;
  pointer-events: none;
}

.signature__close {
  color: #0578ff;
  font-size: 2em;
  position: absolute;
  left: 20px;
  bottom: 80px;
}

.signature__clear {
  color: #908d9c;
  font-size: 12px;
  position: absolute;
  right: 20px;
  align-self: flex-start;
  margin-top: 10px;
}

.signature__date {
  color: #908d9c;
  font-size: 12px;
  position: absolute;
  left: 20px;
  align-self: flex-start;
  margin-top: 10px;
  width: calc(100% - 85px);
  text-align: left;
}

.signature__sign-here {
  color: #908d9c;
  font-size: 16px;
  margin: 15px 10px;
  position: absolute;
  width: 75px;
}
