@import '../../../styles/bootstrap-variables';

.job-card {
  border-radius: 10px;
  border: solid 1px #dbdde2;
  padding: 16px 16px 48px 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 24px;

  a.btn {
    color: white;

    &:hover {
      color: white;
    }
  }

  .btn {
    font-family: $font-family-monserrat;
  }
}

.job-card__job-info {
  text-align: left;
  margin: 0 auto;
  max-width: 230px;
}

.job-card__job-id {
  font-size: 22px;
  letter-spacing: 0.9px;
  margin-bottom: 8px;
}

.job-card__date {
  margin-bottom: 1rem;
}

.job-card__company-name {
  font-weight: $font-weight-bold;
}

.job-card__service-name {
  margin-bottom: 24px;
}

.job-card__view-receipt-btn {
  bottom: 0;
  display: block !important;
  left: 50%;
  max-width: 224px;
  position: absolute;
  transform: translate(-50%, 50%);
  width: 100%;
}

@media all and (min-width: 768px) {
  .job-card {
    flex-direction: row;
    padding: 20px 32px 20px 20px;
  }

  .job-card__image {
    margin-right: 42px;
    height: 152px;
    width: 152px;
  }

  .job-card__payment {
    margin-left: auto;
  }

  .job-card__job-info {
    margin: 0;
  }
}
