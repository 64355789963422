@import '../../styles/bootstrap-variables';

.total-payment {
  display: flex;
  flex-direction: row;
  font-weight: $font-weight-bolder;
  line-height: 2.5rem;
}

.total-payment__dollar {
  align-self: center;
}

.total-payment__amount {
  font-size: 2.5rem;
}

.total-payment__cents {
  align-self: end;
  line-height: 1rem;
  margin-top: .25rem;
}
