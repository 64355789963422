@import '~bootstrap/scss/functions';
@import '../../styles/bootstrap-variables';

.connect-tech-settings {
  background-color: $white-inactive;
  padding-bottom: 10px;
  min-height: 100%;

  &__loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  &__title {
    padding-bottom: 40px;
    width: 80%;

    &__header {
      font-family: $font-family-monserrat;
      font-weight: $font-weight-bold;
      font-size: 24px;
      padding-left: 24px;
      padding-top: 30px;
    }

    &__question {
      padding-top: 30px;
      padding-bottom: 25px;
      padding-left: 5px;
    }

    &__subheader {
      font-family: $font-family-lato;
      font-weight: $font-weight-normal;
      font-size: 16px;
      padding-left: 24px;
      padding-right: 24px;
      margin-bottom: 0;
    }
  }

  &__section {
    margin-bottom: 40px;

    &-title {
      color: $gray-metal;
      font-family: $font-family-lato;
      font-weight: $font-weight-bolder;
      font-size: 14px;
      padding-left: 16px;
    }
  }

  &__back-arrow {
    margin-top: 36px;
    margin-left: 16px;
  }

  &__error {
    position: absolute;
    width: 210px;
    height: 28px;
    left: 82px;
    top: 32px;
    border-radius: 38px;
    padding: 8px 7px 8px 7px;
    background: $red-error;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    font-family: $font-family-lato;
    font-size: 12px;
    text-align: center;
    line-height: 12px;
    color: white;
  }

  &__error-page {
    min-height: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__icon {
      margin-bottom: 16px;
    }

    &__title {
      font-size: 18px;
      font-weight: 600;
      font-family: $font-family-monserrat;
    }

    &__subtitle {
      font-size: 16px;
      font-family: $font-family-lato;
      color: $gray-metal;
    }

    &__button {
      width: 98px;
      height: 44px;
      border-radius: 13px;
      background-color: $blue-light;
      color: $blue-primary;
      font-size: 12px;
      border: none;
      margin-top: 16px;
    }
  }
}
