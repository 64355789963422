@import '../../styles/bootstrap-variables';

.receipt-feedback-page-container {
  width: 100%;
  min-height: 100%;
  background: #f7f9fc;
  padding: 24px 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.receipt-feedback-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  background: #fff;
  padding: 40px 32px;
  font-size: 12px;
  font-family: $font-family-lato;
  width: 100%;
}

.feedback-full-page {
  justify-content: center;
  height: calc(100vh - 50px);
}

.receipt-feedback-page__title {
  font-size: 18px;
  margin-bottom: 18px;
}

.receipt-feedback-page__stars {
  padding-top: 16px;
  padding-bottom: 16px;
}

.receipt-feedback-page__image {
  margin-bottom: 24px;
  width: 60px;
}

.receipt-feedback-page__subtext {
  margin-top: 32px;
  font-size: 12px;
}

.receipt-feedback-page__star-subtext {
  width: 100%;
  font-size: 12px;
  text-align: left;
  color: #332c4a;
  font-family: $font-family-monserrat;
  font-weight: 600;
}

.receipt-feedback-page__feedback-option {
  height: 41px;
  width: 100%;
  margin: 2px 0;
  background: #fafbfe;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.receipt-feedback-page__feedback-option-container {
  margin: 16px 0;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.receipt-feedback-page__feedback-notes {
  background: #f6f9fe;
  border-radius: 10px;
  width: 100%;
  height: 112px;
  resize: none;
  border: none;
  padding: 12px;
}

.receipt-feedback-page__submit {
  background: #004dcb;
  border-radius: 14px;
  width: 100%;
  height: 48px;
  border: none;
  font-family: $font-family-monserrat;
  font-weight: 900;
  font-size: 14px;
  margin-top: 36px;
  color: #f2f2f2;
}

@media all and (min-width: 768px) {
  .receipt-feedback-page__title {
    font-size: 38px;
  }

  .receipt-feedback-page {
    max-width: 592px;
  }
}
