.head {
  padding: 16px 24px 8px 24px;

  &__title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
  }

  &__description {
    font-size: 16px;
    margin-bottom: 0;
  }

  &__back {
    margin-bottom: 16px;

    button {
      display: block;
    }

    img {
      display: block;
      margin-left: -6px;
    }
  }
}
