@import '../../styles/mixins.scss';

.year-picker {
  &__container {
    width: calc(100% - 50px);
    margin-left: 25px;
  }

  &__description {
    margin-top: 12px;
    width: 100%;
  }

  &__select {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #332c4a;

    &-container-bottom {
      @include select-container;

      margin-top: 16px;
    }

    &-container-top {
      @include select-container;

      margin-top: 32px;
    }

    &-box {
      border: none;
      width: 35%;
      text-align-last: right;
      padding-right: 30px;
      color: #646466b3;
      background:
        url('../../images/ConnectTechSettings/icon-year-picker.svg')
        no-repeat right #f6f9fe;
    }
  }
}

select.year-picker__select-box {
  appearance: none;
}

select.year-picker__select-box:focus {
  outline-width: 0;
}
