@import '../../styles/bootstrap-variables';

$footer-color: #6c728b;
$default-theme-color: linear-gradient(to right, #05c7f5 0%, #2378ff 100%);

.receipt-page-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  background: linear-gradient(180deg, #f2f6fa 0%, #fff 100%);

  > * {
    width: 100%;
  }

  @include media-breakpoint-up(sm) {
    min-height: 100%;
    padding: 2rem 1.5rem .5rem 1.5rem;

    > * {
      max-width: 592px;
    }
  }
}

.receipt-page-layout__shadow {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
}

.receipt-page-layout__top {
  background: $default-theme-color;
  color: $white;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding: 1.25rem 1rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.receipt-page-layout__bottom {
  background: $default-theme-color;
  padding: 1.5rem 1rem 1rem 1rem;
  height: 60px;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.receipt-page-layout__top-image,
.receipt-page-layout__bottom-image {
  display: block;
  width: 100%;
}

.receipt-page-layout__service-company {
  font-family: $font-family-monserrat;
  font-size: $font-size-med;
  font-weight: $font-weight-bold;
  flex: 1;
  margin: 0;
  text-transform: uppercase;
  white-space: nowrap;
}

.receipt-page-layout__service-date {
  flex: 0;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  white-space: nowrap;
}

.receipt-page-layout__content {
  background-color: #fff;
  flex: 1;
  padding: 1.5rem;
}

.receipt-page-layout__footer {
  font-size: $font-size-sm;
  letter-spacing: 0.3px;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  text-align: center;
}

.receipt-page-layout__footer-contacts {
  color: #6c728b;
}
