@import '../../styles/bootstrap-variables';

.connect-tech-settings__location {
  background-color: $white;

  &__body {
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 12px;

    &__info {
      font-family: $font-family-lato;
      font-size: 16px;
      margin-bottom: 24px;
    }

    &__subtext {
      margin-left: 24px;
      margin-top: -10px;
      font-size: 12px;
      color: #646466;
    }

    &__title {
      margin-bottom: 12px;
    }

    &__zip {
      height: 64px;
      width: 100%;
      background-color: $white-inactive;
      padding: 8px 16px 16px 16px;
      border-radius: 10px;
      margin-bottom: 16px;

      &__label {
        font-family: $font-family-lato;
        font-size: 12px;
        color: $gray-metal;
        width: 48px;
      }

      &__input {
        float: right;
        text-align: right;
        background-color: transparent;
        color: $not-black;
        border: none;
        line-height: 24px;
        font-size: 16px;
        vertical-align: top;
      }
    }

    &__location-data {
      height: 106px;
      width: 100%;
      background-color: $white-inactive;
      padding: 8px 16px;
      border-radius: 10px;

      &__header {
        display: flex;
        justify-content: space-between;
      }

      &__subheader {
        width: 255px;
        color: $gray-metal;
        font-size: 14px;
      }
    }
  }

  &__modal {
    height: 190px;
    width: 278px;
    background-color: white;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    &__title {
      font-family: $font-family-lato;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      margin-top: 24px;
      font-weight: 700;
    }

    &__body {
      font-family: $font-family-lato;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      margin: 16px 24px 24px 24px;
    }

    &__footer {
      display: flex;

      &__cancel {
        width: 139px;
        height: 48px;
        background-color: $blue-light;
        color: $gray-metal;
        text-align: center;
        line-height: 48px;
        border-bottom-left-radius: 10px;
      }

      &__continue {
        width: 139px;
        height: 48px;
        background-color: $blue-dark;
        color: $white;
        text-align: center;
        line-height: 48px;
        border-bottom-right-radius: 10px;

        &__link {
          color: $white;

          &:hover {
            color: $white;
            text-decoration: none;
          }
        }
      }
    }
  }
}
