@import '../../styles/bootstrap-variables';

.search-page__error {
  color: #bf6969;
  font-family: $font-family-lato;
  font-size: 14px;
  text-align: center;
}

.search-page__section {
  flex: 1;
  padding-bottom: 40px;
}

.search-page__section--center {
  text-align: center;
}

.search-page__section-header {
  font-size: $font-size-mlg;
  font-weight: 300;
  text-align: center;
}

.search-page__about-header {
  font-size: $font-size-base;
  font-weight: 300;
  text-transform: uppercase;
}

.search-page__section + .search-page__section {
  border-top: 1px solid #dddfe4;
  padding-top: 40px;
}

.search-page__help-page-link {
  text-align: center;
}

.search-page__search-form {
  margin: 0 46px 32px 46px;
  min-width: 180px;
}

.search-page__about {
  color: #979797; // $text-muted
  font-weight: 300;
}

.search-form__search-icon {
  margin-right: .5rem;
}

.search-page__occupations {
  margin-left: 24px;
  margin-top: 27px;
}

.search-page__occupation + .search-page__occupation {
  margin-top: 8px;
}

.search-page__occupation-icon {
  display: inline-block;
  height: 24px;
  margin-right: 30px;
  vertical-align: bottom;
  width: 24px;
}

@media all and (min-width: 768px) {
  .search-page {
    .header {
      height: 475px;
    }
  }

  .search-page__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 0;
    transform: translateY(-150px);
  }

  .search-page__card {
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    padding: 40px;
    width: 830px;
  }

  .search-page__error {
    margin-bottom: 24px;
  }

  .search-page__section {
    border: none;
    padding: 0 40px 0;
  }

  .search-page__section + .search-page__section {
    border-left: 1px solid #dddfe4;
    border-top: none;
    padding-left: 40px;
    padding-top: 0;
  }

  .search-page__section-header {
    text-align: left;
    margin-bottom: 24px;
  }

  .search-page__about-section {
    padding-top: 80px;
    width: 600px;
  }
}

@media all and (min-width: 1050px) {
  .search-page__content {
    transform: translateY(-190px);
  }
}
